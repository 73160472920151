<template>
<div>
    <v-row justify="end">
        <v-col cols="12" sm="12" md="8" lg="6">
            <el-input suffix-icon="el-icon-search" v-model="search" clearable placeholder="Search">
                <template slot="prepend">
                    <el-button @click="addTag()"><i class="el-icon-plus"></i></el-button>
                </template>
            </el-input>
        </v-col>
    </v-row>
    <el-container style="height: 75vh">
        <el-main>
            <v-data-table dense :loading="loading" :items="filteredTags" :search="search" :headers="headers">
                <template v-slot:item.action="{ item }">
                    <el-button plain @click="editTag(item)" size="small" icon="el-icon-edit" circle></el-button>
                    <el-button size="small" type="danger" icon="el-icon-delete" circle @click="removeTag(item)"></el-button>
                </template>
            </v-data-table>
        </el-main>
    </el-container>

    <el-dialog :visible.sync="modal" :fullscreen="$vuetify.breakpoint.mobile" width="750px" :title="item.modalType + ' Tag'" :closed="clear">
        <el-form class="modal-form" :model="item" ref="form" @submit.native.prevent="saveTag">
            <el-form-item prop="name">
                <el-input v-model="item.name" placeholder="Name" prefix-icon="fas fa-user" clearable>
                    <v-icon v-if="item.name" slot="suffix" class="mr-1" small color="green">check</v-icon>
                    <v-icon v-else slot="suffix" class="mr-1" small color="orange">warning</v-icon>
                </el-input>
            </el-form-item>
            <el-form-item prop="type">
                <el-input v-model="item.type" placeholder="Type" prefix-icon="fas fa-user" clearable>
                    <v-icon v-if="item.type" slot="suffix" class="mr-1" small color="green">check</v-icon>
                    <v-icon v-else slot="suffix" class="mr-1" small color="orange">warning</v-icon>
                </el-input>
            </el-form-item>
            <el-form-item label="Tag Colour">
                <el-color-picker v-model="item.colour"></el-color-picker>
            </el-form-item>
            <el-form-item style="text-align: center">
                <el-button v-if="item.modalType=='Add'" :loading="loading" style="min-width: 40%" type="primary" :disabled="!item.name || !item.type" native-type="submit" block> {{!loading?'Submit':''}}</el-button>
                <el-button v-else :loading="loading" style="min-width: 40%" type="primary" :disabled="!item.name || !item.type" native-type="submit" block> {{!loading?'Save':''}}</el-button>

            </el-form-item>
        </el-form>
    </el-dialog>
</div>
</template>

<script>
export default {
    data: () => ({
        item: {},
        headers: [{
                text: 'Action',
                align: 'center',
                sortable: false,
                value: 'action'
            },
            {
                text: 'Name',
                value: 'name',
                align: 'center',
            },
            {
                text: 'Type',
                value: 'type',
                align: 'center',
            },
        ],
        loading: false,
        modal: false,
        search: '',
        tags: []
    }),
    created() {
        this.loadTags()
    },
    computed: {
        filteredTags() {
            let result = this.tags
            if (this.search) {
                result = result.filter(x => (x.name.toLowerCase()).includes(this.search.toLowerCase()) || (x.type.toLowerCase()).includes(this.search.toLowerCase()))
            }
            return result
        }
    },
    methods: {
        addTag() {
            this.item = {
                modalType: 'Add'
            }
            this.modal = true
        },
        editTag(item) {
            this.item = item
            this.item.modalType = 'Edit'
            this.modal = true
        },
        clear() {
            this.item = {}
            this.modal = false
        },
        async loadTags() {
            this.loading = true
            this.tags = await this.$API.getTagsAdmin()
            this.loading = false
        },
        async removeTag(tag) {
            this.$confirm('Are you sure you want to delete this Tag?', 'Confirm', {
                center: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                type: 'plain'
            }).then(async () => {
                await this.$API.updateTagAdmin({
                    id: tag.id,
                    isActive: false,
                    isDeleted: true
                })
                this.loadTags()
                this.$message({
                    type: 'success',
                    message: 'Successfully deleted!'
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: 'Delete canceled'
                });
            });
        },
        async saveTag() {
            if (this.item.id) {
                await this.$API.updateTagAdmin(this.item)
                this.$message.success('Successfully updated')
                this.modal = false
                this.item = {}
            } else {
                await this.$API.createTagAdmin(this.item)
                this.loadTags()
                this.$message.success('Successfully created')
                this.modal = false
                this.item = {}
            }
        }
    }
}
</script>
